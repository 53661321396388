import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import '../Styling/AddedCity.css'; // Import the CSS file

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const AddedCity = () => {
  const [cities, setCities] = useState([]);
  const [message, setMessage] = useState('');

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cities`);
      setCities(response.data);
    } catch (error) {
      setMessage(`Error fetching cities: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <div className="added-cities-container">
      <h2>Available Cities</h2>
      <br/>
      {message && <p>{message}</p>}
      <ul className="city-list">
        {cities.map((city) => (
          <li key={city.id} className="city-item">
            <Link to={`/form/${city.name}`} className="city-button">
              {city.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddedCity;
