import React, { useState } from "react";
import "../Styling/Header.css";
import imgLogo from "../Assets/logo.png";
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const Header = () => {
  const [showAddCityPopup, setShowAddCityPopup] = useState(false);
  const [cityName, setCityName] = useState("");
  const [message, setMessage] = useState("");
  const [cities, setCities] = useState([]);

 

  const handleAddCity = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/cities/add`, {
        name: cityName,
      });
      if (response && response.data) {
        setMessage(`City "${response.data.name}" added successfully!`);
        setCities([...cities, response.data]);
      } else {
        setMessage("City added, but no data returned from server.");
      }
      setShowAddCityPopup(false);
      setCityName("");
      window.location.reload();

    } catch (error) {
      setMessage(
        `Error adding city: ${
          error.response ? error.response.data.error : error.message
        }`
      );
    }
  };

  return (
    <>
      <header className="header">
        <div className="logo">
          <img src={imgLogo} alt="Logo" />
        </div>
        <div className="buttons">
          <button>Show Count</button>
          <button onClick={() => setShowAddCityPopup(true)}>Add New City</button>
        </div>
      </header>
      {showAddCityPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Add City</h2>
            <input
              type="text"
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
              placeholder="Enter city name"
            />
            <div className="popup-buttons">
              <button className="submit-btn" onClick={handleAddCity}>Submit</button>
              <button className="close-btn" onClick={() => setShowAddCityPopup(false)}>Close</button>
            </div>
            {message && <p className="message">{message}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
