import React from 'react';
import axios from 'axios';

const TableDownload = ({ tableName }) => {
  const handleDownload = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/download/${tableName}`, {
        responseType: 'blob', // Important for binary data like files
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${tableName}_data.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading table data:', error);
      // Handle error display or logging
    }
  };

  return (
    <div>
      <h3>{tableName}</h3>
      <button onClick={handleDownload}>Download Data</button>
    </div>
  );
};

export default TableDownload;
