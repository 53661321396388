import React, { useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
// import './FormPage.css'; // Optional CSS for the form page
import './Styling/FormPage.css'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function FormPage() {
  const { cityName } = useParams();
  const [formData, setFormData] = useState({
    homeTown: cityName,
    livingCity: '',
    name: '',
    phone: '',
    email: '',
    remark: '',
    services: [],
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        services: checked
          ? [...prevData.services, value]
          : prevData.services.filter((service) => service !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/${cityName.toLowerCase()}_form/add`, formData);
      if (response.data.success) {
        setMessage(`Form submitted successfully for ${cityName}`);
        // Clear form data
        setFormData({
          ...formData,
          livingCity: '',
          name: '',
          phone: '',
          email: '',
          remark: '',
          services: [],
        });
        // Refresh page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setMessage('Failed to submit form');
      }
    } catch (error) {
      setMessage(`Error submitting form: ${error.message}`);
    }
  };

  return (
    <div className="form-page">
        <Link to="/">Home</Link> {/* Home button */}
      <h2>{`Form for ${cityName}`}</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="homeTown">Home Town:</label>
        <input type="text" id="homeTown" name="homeTown" value={formData.homeTown} readOnly />
        <br />
        <label htmlFor="livingCity">Living City:</label>
        <input type="text" id="livingCity" name="livingCity" value={formData.livingCity} onChange={handleChange} required />
        <br />
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        <br />
        <label htmlFor="phone">Phone:</label>
        <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
        <br />
        <label htmlFor="email">Email Address:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
        <br />
        <label htmlFor="remark">Remark:</label>
        <textarea id="remark" name="remark" value={formData.remark} onChange={handleChange}></textarea>
        <br />
        <label>Our Services:</label>
        <br />
        {['3 wheel leasing', 'Gold loan', 'Eshift', 'FD', 'General leasing', 'Savings', 'Home loan', 'Credit card', 'GL other bank'].map((service) => (
          <div key={service}>
            <input
              type="checkbox"
              id={service}
              name="services"
              value={service}
              checked={formData.services.includes(service)}
              onChange={handleChange}
            />
            <label htmlFor={service}>{service}</label>
          </div>
        ))}
        <br />
        <button className="submit-btn" type="submit">Submit</button>
        
        {message && <div className="message">{message}</div>}
      </form>
      
    </div>
  );
}

export default FormPage;
