import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import FormPage from './FormPage';
import './App.css';
import CityCountsPage from './CityCountsPage';
import TableDownload from './TableDownload';
import Header from './Components/Header';
import AddedCity from './Components/AddedCity';
import Download from './Components/Download';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function App() {
  const [cityName, setCityName] = useState('');
  const [showAddCityPopup, setShowAddCityPopup] = useState(false);
  const [message, setMessage] = useState('');
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cities`);
      setCities(response.data);
    } catch (error) {
      setMessage(`Error fetching cities: ${error.message}`);
    }
  };

  const handleAddCity = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/cities/add`, { name: cityName });
      if (response && response.data) {
        setMessage(`City "${response.data.name}" added successfully!`);
        setCities([...cities, response.data]);
      } else {
        setMessage('City added, but no data returned from server.');
      }
      setShowAddCityPopup(false);
      setCityName('');
    } catch (error) {
      setMessage(`Error adding city: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  const openCityForm = (city) => {
    navigate(`/form/${city.name}`);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src="logo.png" alt="Logo" className="App-logo" />
        <button className="add-city-btn" onClick={() => setShowAddCityPopup(true)}>Add City</button>
        {message && <div className="message">{message}</div>}
        {showAddCityPopup && (
          <div className="popup">
            <div className="popup-content">
              <h2>Add City</h2>
              <input
                type="text"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                placeholder="Enter city name"
              />
              <button className="submit-btn" onClick={handleAddCity}>Submit</button>
              <button className="close-btn" onClick={() => setShowAddCityPopup(false)}>Close</button>
            </div>
          </div>
        )}
      </header>
      <main>
        <h2>Added Cities</h2>
        <ul>
          {cities.map((city) => (
            <li key={city.id}>
              <Link to={`/form/${city.name}`}>{city.name}</Link>
            </li>
          ))}
        </ul>
        <h2>Data Download</h2>
        <TableDownload tableName="Matale_form" />
        <TableDownload tableName="another_table" />
      </main>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/a' element={<AddedCity />} />
          <Route path="/" element={<App />} />
          <Route path='/download' element={<Download />} />
          <Route path="/form/:cityName" element={<FormPage />} />
          <Route path="/city-counts" element={<CityCountsPage />} />
        </Routes>
      </Router>
    </>
  );
}
