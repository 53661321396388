import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function CityCountsPage() {
  const [cityCounts, setCityCounts] = useState([]);

  useEffect(() => {
    const fetchCityCounts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/cities/count`);
        setCityCounts(response.data);
      } catch (error) {
        console.error('Error fetching city counts:', error);
      }
    };

    fetchCityCounts();
  }, []);

  return (
    <div className="city-counts">
      <h2>City Data Counts</h2>
      <ul>
        {cityCounts.map((city) => (
          <li key={city.name}>
            {city.name}: {city.count} entries
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CityCountsPage;
